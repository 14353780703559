import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Steps from '../components/Steps';
import HowTTWorks from '../components/tables/HowTTWorks';
import HowTTWorksMobile from '../components/tables/HowTTWorksMobile';
import TableTabs from '../components/TableTabs';
import { matchSorter } from 'match-sorter';
import Edges from '../components/Edges';
import Seo from '../components/Seo';
import PageHeader from '../components/PageHeader';

const HowTradeTrainingWorksPage = (props) => {
    const {
        uri,
        pageContext,
        location,
        data: { allPublicProgram, allCourseRefresher },
    } = props;

    const pageTitle = 'How Trades Training Works';

    const tableHeaders = [{ title: 'Program Name' }, { title: 'Red Seal' }, { title: '' }];

    const upgradingTableHeaders = [{ title: 'Institution' }, { title: 'Program Name' }, { title: 'Region' }];

    const ItaLink = `<a href="https://skilledtradesbc.ca/youth" target="_blank" className="text-blue">Youth</a>`
    const RefresherLink = `<a href="/CourseRefresher" className="text-blue">REFRESHER PROGRAMS</a>`
    const tabs = {
        header: 'TRADES TRAINING PROGRAM TYPES',

        tabTitles: [
            {
                title: 'Apprenticeship',
                titleKey: 'apprentice',
                content: `Apprenticeship is a combination of on-the-job and in-school training. An employer must sponsor an apprentice, which means that person works and earns a salary, but also takes some time for classroom learning — typically once per year.  Successful completion of both components, along with examinations, is required to earn a certificate —AKA “ticket”— to become a certified tradesperson. Most trades take 4=four years to complete, with each year corresponding to a higher-numbered trade level, i.e. 1 through 4. The in-school technical training portion can range from 4 to 10 weeks per year, depending on the trade. Programs that are indicated as RED SEAL involve a further examination that can provide a Canada-wide designation of proficiency in the associated trade.`,
            },
            {
                title: 'Foundation',
                titleKey: 'foundation',
                content: `Foundation training programs provide trainees with the basic knowledge and skills needed to enter a particular occupation. They are typically conducted in a classroom or shop setting, without involving any significant work-based training component. As a result, trainees do not require an employer/sponsor to participate. Full-time Foundation programs can vary from 13 weeks to 10 months in duration.
<br/><br/>
Completion of a Foundation program will provide students with the relevant skills to be employable within their industry. Also, students usually receive credit for the first level, and with agreement from their employer, they will have the ability to pursue an apprenticeship. A student who completes a Foundation program is highly sought after by employers as they have attained the necessary skills to be immediately productive on the work site. Students may also apply the credits earned from the Foundation program for entrance into another program, depending upon the institution’s requirements and the particular area of study.`,
            },
            {
                title: 'Technical',
                titleKey: 'vocational',
                content: `These programs tend to lead to an institutional credential such as a Diploma of Trades or government credential other than the interprovincial Red-Seal. Varying in length, some may be as long as two years in duration while others may be similar to a Foundation program. They provide a blend of academic and practical skills and, similar to apprenticeship programs, some require time working in industry before certification can be achieved. Each educational provider offers a range of this programming depending on their region and mandate.`,
            },
            {
                title: 'Youth',
                titleKey: 'youth',
                content: `SkilledTradesBC offers various programs targeting Youths, that let you get started in a trade while you’re still in high school. That means you could be working in your field, learning from experts and earning a paycheque — all before you graduate! For details, visit SkilledTradeBC’s ${ItaLink} website.`,
            },
            {
                title: 'Upgrading',
                titleKey: 'upgrading',
                content: `Current and future course refresher (CQ/IP) training and general Trades updating of specific key subject areas are listed here. This training is geared to those wishing to challenge a SkilledTradesBC certification exam or for existing apprentices in need of skills updating. Some of these courses have specific start and end dates, while others are continuous entry/distance education offered throughout the year. Refer to our ${RefresherLink} page to contact the institution of your choice for more information. Many of these courses are offered on an as-needed basis, so by contacting the trainer of choice, they can better plan this training.`,
            },
        ],
    };

    const mobileTableHeaders = [{ title: 'Program Name' }, { title: 'Red Seal' }];
    const [selectedSector, setSelectedSector] = useState('apprentice');

    const handleSearch = (e) => {
        if (e.target.value) {
            setSelectedSector(e.target.value);
        } else {
            setSelectedSector(null);
        }
    };

    const programSort = (a: typeof allPublicProgram.node, b: typeof allPublicProgram.node) => {
        return (a.title < b.title ? -1 : 1);
    };

    const filteredPublicPrograms = selectedSector
        ? matchSorter(allPublicProgram.nodes, selectedSector, {
            keys: ['publicProgramTypeIds'],
            sorter: matchedItems => matchedItems.sort(programSort),
        })
        : allPublicProgram.nodes;

    return (
        <>
            <Seo title={pageTitle} />

            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: uri }]} />

            <Steps />
            <div className='bg-lightGray pt-[74px] pb-[59px]'>
                <Edges size='md'>
                    <div className='mx-auto flex grow flex-col bg-white p-[15px] shadow-tableShadow sm:p-[40px]'>
                        <TableTabs
                            tabs={tabs}
                            programTypes={allPublicProgram.nodes}
                            onSearch={handleSearch}
                            {...pageContext}
                            location={location}
                            uri={uri}
                            selectedSector={selectedSector}
                            setSelectedSector={setSelectedSector}
                        />

                        <div className='hidden sm:block'>
                            <HowTTWorks
                                refreshers={allCourseRefresher}
                                table={filteredPublicPrograms}
                                uri={uri}
                                headers={tableHeaders}
                                selectedSector={selectedSector}
                                upgradingTableHeaders={upgradingTableHeaders} />
                        </div>
                        <div className='block sm:hidden'>
                            <HowTTWorksMobile
                                refreshers={allCourseRefresher}
                                table={filteredPublicPrograms}
                                uri={uri}
                                headers={mobileTableHeaders}
                                selectedSector={selectedSector}
                                upgradingTableHeaders={upgradingTableHeaders}
                            />
                        </div>
                    </div>
                </Edges>
            </div>
        </>
    );
};

export default HowTradeTrainingWorksPage;

export const pageQuery = graphql`
    query HowTTWorksQuery {
        allPublicProgram(sort: {fields: name, order: ASC}) {
            nodes {
                uri
                name
                isRedSeal
                redSealURL
                workBCURL
                publicProgramTypeIds
                slug
                itaurl
                hasApprenticeSchedule
                hasFoundationSchedule
                hasVocationalSchedule
                hasYouthSchedule
                isChallengeExam
                sector {
                    slug
                    name
                }
                schedules {
                    apprenticeLevel
                }
            }
        }
        allCourseRefresher(sort: { fields: [institution, name, region], order: [ASC, ASC, ASC] }) {
            nodes {
                institution
                name
                databaseId
                region
                url
            }
        }
    }
`;
