/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Link } from "gatsby"

export default function PrimaryTable(props) {
  const { table, headers, refreshers, selectedSector, upgradingTableHeaders } =
    props

  return (
    <div className="flex flex-col">
      <div className="flex shadow-tableShadow sm:rounded-lg">
        <table className="w-full">
          <thead className={"bg-logoBG"}>
            <tr>
              {headers &&
                selectedSector != "upgrading" &&
                headers.map((header, index) => {
                  return index > 0 ? (
                    <th
                      key={index}
                      scope="col"
                      className={
                        " whitespace-nowrap px-6 py-3 uppercase tracking-wider text-gray500 "
                      }
                    >
                      <h3>{header.title}</h3>
                    </th>
                  ) : (
                    <th
                      key={index}
                      scope="col"
                      className={
                        " whitespace-nowrap px-6 py-3 text-left uppercase tracking-wider text-gray500"
                      }
                    >
                      <h3>{header.title}</h3>
                    </th>
                  )
                })}
              {upgradingTableHeaders &&
                selectedSector === "upgrading" &&
                upgradingTableHeaders.map((header, index) => {
                  return index > 0 ? (
                    <th
                      key={index}
                      scope="col"
                      className={
                        " whitespace-nowrap px-6 py-3 text-left uppercase tracking-wider text-gray500 "
                      }
                    >
                      <h3>{header.title}</h3>
                    </th>
                  ) : (
                    <th
                      key={index}
                      scope="col"
                      className={
                        " whitespace-nowrap px-6 py-3 text-left uppercase tracking-wider text-gray500"
                      }
                    >
                      <h3>{header.title}</h3>
                    </th>
                  )
                })}
            </tr>
          </thead>
          {selectedSector != "upgrading" && (
            <tbody>
              {table.map((program, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-logoBG "}
                >
                  <td className=" text-sm w-[554px] whitespace-nowrap px-6 py-4 font-robotoCondensed  text-pTable text-gray900">
                    {program.name}
                  </td>

                  {program.isRedSeal ? (
                    <td className="text-sm text-gray-500  whitespace-nowrap px-6 py-4 text-center">
                      {program.isRedSeal && "✔"}
                    </td>
                  ) : (
                    <td className="text-sm text-gray-500 h-[56px]  whitespace-nowrap px-6 py-4 text-center"></td>
                  )}

                  <td className="pr-[5px]">
                    <Link to={program.uri}>
                      <button className="btn-light hover:text-blue hover:border-blue">Learn More</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {selectedSector === "upgrading" && (
            <tbody>
              {refreshers.nodes.map((program, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-logoBG "}
                >
                  <td className="text-sm px-6 py-4 text-left font-robotoCondensed  text-pTable text-gray900">
                    {program.institution}
                  </td>

                  {program.name ? (
                    <td className="text-sm text-gray-500   px-6 py-4 text-left font-robotoCondensed text-pTable ">
                      {program.name}
                    </td>
                  ) : (
                    <td className="text-sm text-gray-500 text-pTablepx-6  h-[56px] py-4 text-left font-robotoCondensed  "></td>
                  )}

                  <td className=" text-sm px-6 py-4 text-left font-robotoCondensed text-pTable text-gray900">
                    {program.region}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  )
}
