/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Link } from "gatsby"
export default function MobileTable(props) {
  const { table, headers, refreshers, selectedSector, upgradingTableHeaders } =
    props

  return (
    <div className="flex flex-col">
      <div className="-my-2 mb-[32px]">
        <div className="py-2 align-middle">
          <div className="overflow-x-auto rounded-lg shadow-tableShadow">
            <table className="block min-w-full md:hidden">
              {table.map((program, index) => (
                <tbody key={index} className="flex flex-row">
                  <tr>
                    {headers &&
                      selectedSector != "upgrading" &&
                      headers.map((header, index) => {
                        return (
                          <th
                            key={index}
                            scope="col"
                            className={
                              "flex h-[47px] flex-col whitespace-nowrap px-3 py-4 text-left uppercase tracking-wider text-gray500"
                            }
                          >
                            <h3>{header.title}</h3>
                          </th>
                        )
                      })}
                  </tr>

                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "flex flex-1 flex-col bg-white"
                        : "flex flex-1 flex-col bg-logoBG"
                    }
                  >
                    <td className="text-sm h-[47px] px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                      {program.name}
                    </td>

                    {program.isRedSeal ? (
                      <td className="text-sm text-gray-500 h-[47px] whitespace-nowrap px-3 py-3">
                        {program.isRedSeal && (
                          <div className="ml-[30px] md:ml-[0px]">✔</div>
                        )}
                      </td>
                    ) : (
                      <td className="text-sm text-gray-500 h-[47px] whitespace-nowrap px-3 py-3"></td>
                    )}

                    <td
                      className={
                        index % 2 === 0
                          ? "my-[11px] ml-[10px] bg-white"
                          : "my-[11px] ml-[10px] bg-logoBG"
                      }
                    >
                      <Link to={program.uri}>
                        <button className="btn-light">Learn More</button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              ))}
              
              {selectedSector === "upgrading" && refreshers.nodes.map((program, index) => (
                <tbody key={index} className="flex flex-row">
                  <tr>
                    {upgradingTableHeaders &&
                      selectedSector === "upgrading" &&
                      upgradingTableHeaders.map((header, index) => {
                        return (
                          <th
                            key={index}
                            scope="col"
                            className={
                              "flex h-[47px] flex-col whitespace-nowrap px-3 py-4 text-left uppercase tracking-wider text-gray500"
                            }
                          >
                            <h3>{header.title}</h3>
                          </th>
                        )
                      })}
                  </tr>

                  <tr
                    key={index}
                    className={
                      index % 2 === 0
                        ? "flex flex-1 flex-col bg-white"
                        : "flex flex-1 flex-col bg-logoBG"
                    }
                  >
                    <td className="text-sm h-[47px]  px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                      {program.institution}
                    </td>

                    {program.name ? (
                      <td className="text-sm text-gray-900 flex h-[47px] items-center px-3 py-3 font-robotoCondensed text-pTable">
                        {program.name}
                      </td>
                    ) : (
                      <td className="text-sm text-gray-900 h-[47px] px-3 py-3 font-robotoCondensed text-pTable"></td>
                    )}

                    <td className="text-sm h-[47px]  px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                      {program.region}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
