import * as React from "react"
import Arrow from "../images/icons/Arrow.svg"
import Edges from "../components/Edges"
import HTMLReactParser from "html-react-parser";
function Steps() {
  const header = "What Are The Steps?"
  const ItaLink = `<a href="https://skilledtradesbc.ca/" target="_blank" className="text-blue">SkilledTradesBC</a>`;
  const steps = [
    {
      title: "Choose a Trade:",
      text: `This site lists up-to-date training schedules from BC's public post-secondary institutions for skilled-trade programs. Most programs —Apprentice, Foundation, and Youth— are administered by ${ItaLink}. Not all trade programs are listed however; only the ones that the consortium partners offer which can be shown live.`,
    },
    {
      title: "Choose a Training Type:",
      text: "Programs are classified by their training model. Select amongst the various PROGRAM TYPES below to view their full description.",
    },
    {
      title: "Find a Program near you:",
      text: "Program schedules list the latest offerings with dates. Select the letter-mail icon adjacent to the program of your choice to send an e-mail directly to the institution so that they can respond to your query.",
    },
  ]

  return (
    <Edges size="md">
      <div className="bg-lightGray container mx-auto sm:mb-[45px] px-[40px] py-[34px] sm:shadow-logoShadow">
        {header && <h2 className="mb-[30px] ">{header}</h2>}
        {steps &&
          steps.map((step, i) => (
            <div key={i} className="flex flex-col ">
              <div className="flex flex-row items-center mb-[15px]">
                <div className="h-[22px] w-[22px]">
                  <Arrow className="object-contain" />
                </div>

                <h3 className="ml-[14px] ">{step.title}</h3>
              </div>
              <p className="mb-[25px] max-w-[770px] ml-[37px]">{HTMLReactParser(step.text)}</p>
            </div>
          ))}
      </div>
    </Edges>
  )
}

export default Steps
